.categories-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    /* -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.75);
    background-color: white; */
    /* height: 36px !important; */
  }
  
  .categories-menu-button {
    cursor: pointer;
    height: 40px !important;

  }
  
  .categories-menu-button:focus {
    outline: none;
    border: none;
  }
