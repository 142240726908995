.user-body {
  display: flex;
  align-items: center;
  gap: 7px;
}

.user-body .user-body-content {
  display: grid;
  gap: 2px;
}

.user-body .name {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  color: #3D3D3B;
}

.user-body .email {
  font-size: 11px;
  font-weight: 400;
  line-height: 13.31px;
  text-align: left;
  color: #848484;
}
