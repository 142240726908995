.accordian-container {
    height: auto;
    margin: 10px;
}

.values-container {
    display: flex;
    flex-direction: row;

}

.space-container {
    margin: 10px;
    margin-right: 80px;
}

.data-container {
    width: 196px;
    margin:10px;
    margin-left:0px;
    margin-right: 70px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #595959;
}
.data-containerChild {
    width: 250px;
    margin:10px;
   margin-right: 70px;
}

.heading-containerChild{
    font-weight: bold;
    /* width: 30%; */
    padding: 10px;
    padding-left: 18px;
    margin-right: 266px;
   position: sticky;
}
.PrivateNotchedOutline-root-34{
    border-radius: 5px;
}

.heading-containerChild1{
    font-weight: bold;
    width: 300px;
    
    /* padding: 10px; */
    
    /* margin-right: 280px; */
   position: sticky;

}
.heading-container {
    font-weight: bold;
    width: 30%;
   padding: 10px;
   position: sticky;
   
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 266%;
    /* identical to box height, or 32px */

    letter-spacing: 1px;
    text-transform: uppercase;

    color: #323232;
   
}

.mappings-container {
    width: 70%;
    height: auto;
    margin: 10px;
    /* margin-top: 30px; */
}

.main-mapping-container {
    display:flex;
    flex-direction: row;
}

.heading {
  font-weight: bolder;  
  font-size: large;
}
.page-heading{
    font-Style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: #595959;


}


body {
    font-family: Arial, sans-serif;
    /* margin: 20px; */
  }
  
  .dropdown {
    position: relative;
    width: 200px;
  }
  
  .dropdown-input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .dropdown-menu {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
    /* z-index: 1000; */
  }
  
  .dropdown-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f5f5f5;
  }
  

  