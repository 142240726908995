.label {
    /* width: 232px;
height: 20px; */


font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 143%;
padding-top: 5px;
/* identical to box height, or 20px */


color: #BEBEBE;
}
.Toastify__toast {
    min-height: 36px !important;
    padding: 0px 8px 0px 0px !important;
    width: fit-content;
    align-items: center;
}

.Toastify__toast-container {
    width: auto !important;
}

.MuiAlert-message{
    width: max-content !important;
    padding: 0px 18px 0px 1px !important;
    color: #252425 !important;
    font-weight: 500;
}

.MuiAlert-icon{
    padding: 1px 0px !important;
}

.MuiAlert-colorInfo{
    padding: 1px 0px !important;
    fill: #3d70d4 !important;
}

.Toastify__toast-body {
    padding: 0px 0px 0px 10px !important;
    margin: 0px !important;
    color: rgba(0, 0, 0) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 143% !important;
    font-family: 'Nunito Sans',Arial, Helvetica, sans-serif;
    background-color: #FFFFFF !important;
}

.Toastify__close-button {
    color: rgba(0, 0, 0, 0.6) !important;
    padding: 10px 14px !important;
    margin: 0px !important;
    display: contents;
    width: 18px;
}

.Toastify__toast--error {
    /* background: rgb(253, 237, 237) !important; */
    border-radius: 10px !important;  
    height: 36px !important;
}

.Toastify__close-button--error {
    padding: 10px 14px !important;
    margin: 0px !important;
    
}

.Toastify__progress-bar--error{
    background-color: #cd1515 !important;
    top: 0px;
    height: 2px !important;
}

.Toastify__toast--success {
    border-radius: 10px !important;  
    height: 36px !important;
}

.Toastify__close-button--success {
    padding: 10px 14px !important;
    margin: 0px !important;
    width: 3px;
}

.Toastify__progress-bar--success{
    background-color: #01af45 !important;
    top: 0px;
    height: 2px !important;
}

.Toastify__toast--warning {
    /* background: rgb(255, 244, 229) !important; */
    border-radius: 10px !important;  
    height: 36px !important;
}

.Toastify__close-button--warning {
    padding: 10px 14px !important;
    margin: 0px !important;
    

}

.Toastify__progress-bar--warning{
    background-color: #ff9800 !important;
    top: 0px;
    height: 2px !important;
}

.Toastify__toast--info {
    /* background: rgb(229, 246, 253) !important; */
    border-radius: 10px !important;  
    height: 36px !important;
}

:root {
    --toastify-icon-color-info : #3d70d4 !important;
    --toastify-icon-color-success: #01af45 !important;
    --toastify-icon-color-error: #cd1515 !important /* Override the color for error toasts */
}


.Toastify__close-button--info {
    padding: 10px 14px !important;
    margin: 0px !important;
}

.Toastify__progress-bar--info{
    background-color: #3d70d4 !important;
    top: 0px;
    height: 2px !important;
}
.createButton{
    font-weight: 800;
    line-height: 22px;

}
.createButton1{
    font-weight: 800;
    line-height: 22px;
    border: 1px solid !important;
    border-radius: 16px !important;
}


.Toastify__toast-icon{
    display: none;
    width: 17px !important;
}

.MuiAlert-icon{
    display: none !important;
}

.MuiAlert-root {
    display: none;
    background-color: #FFFFFF !important;
    padding: 0px 0px !important;
}

.Toastify__toast-body > div:last-child {
    flex: 1 1;
    /* padding: 16px 6px; */
    background-color: #FFFFFF !important;
}

.Toastify__toast-container--bottom-left{
    left: 6em
}

/* .MuiTableCell-root {
    padding: 17px 0px 12px 14px !important;
} */

/* .MuiTableCell-root > div:first-child{
    padding: 0px !important;
} */
.MuiTableCell-firstColumn{
    padding: 17px 0px 12px 14px !important;
}

.MuiTableCell-secondColumn{
    padding: 0px !important;
    width:579px !important;
}

.MuiTableCell-thirdColumn{
    padding: 0px !important;
}

.MuiTableCell-forthColumn{
    padding: 12px 24px 12px 0px !important;
}