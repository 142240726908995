
button {
    /* margin: 0.2rem!important; */
    padding: 0.1rem!important;
    color: #101010;
    background: #F9F9F9;
    cursor: pointer;
    border: 0px;
}

/* .active{
    text-decoration: underline;
} */
