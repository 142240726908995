.categories-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: left;
  height: 100vh;
  border-right: 2px solid #EDEDED;
  transition: all 0.1s ease;
  overflow: hidden;
  position: absolute;
  background-color: #fff;
}

  .header-container-start{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .header-container-end{
    display: flex;
    /* gap: 16px; */
    align-items: center;
    flex-direction: column;
    width: inherit;
    justify-content: center;
    margin-bottom: 8px;
  }
  .header-container-end .logout-root:hover {
    background-color: #F8F9F7;
  }
  .expandIcon{
    width: .7rem;
    height: .7rem;
  }
  .menuListText1{
     display: flex;
    gap: 4px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    align-items: center;
    color: #262626;
  }
  .menuListText2{
    display: flex;
   width: 73%;
   height: 22px;
   top: 467px; 
   cursor: pointer;
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 17px;
   align-items: center;
   color: #A0A0A0;
   justify-content: space-between;
   padding-bottom: 3%;
   padding-left:27% 
 }

 .categories-menu-button .close {
  position: absolute;
  top: 5px;
  left: 10px;
 }

 .categories-menu-button .open {
  position: absolute;
  top: 5px;
  left: 10px;
 }

.categories-container .categories-menu-button {
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  gap: 8px  ;
  align-items: center;
  border-radius: 12px;
  border: none;
  padding: 8px 13px;
  color: #595959;
  background-color: transparent;
}

.categories-container .categories-menu-button:hover {
  background-color: rgb(248, 249, 247);
}

.categories-container .menuList {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  cursor: pointer;
  margin: 0 12px;
  padding: 10px;
  border-radius: 8px;
}
.categories-container .menuList-root:last-child {
  margin-bottom: 12px;
}

.categories-container .menuList:hover {
  background-color: #F0F2EE;
}