.home-img {
    width: 80%;
    margin: auto;
    display: flex;
    margin-top: 8%;
    overflow: hidden;
}

.giphy-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}