#demo-simple-select {
    color: white !important;
    border: 1px solid white !important;
    border-radius: 8px;
    padding-left: 5px;
}

#demo-simple-select:hover {
    color:white !important;
    border: 1px solid white !important;

}
/* #demo-simple-select:focus {
    /* color:white !important; */
    /* border: none; */
    /* outline: none; */

/* }  */

#demo-simple-select > input {
    border-color:rgba(255,255, 255, 1) !important;
}

#demo-simple-select > .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
    color:rgba(255,255, 255, 1) !important;
}

/* #demo-simple-select {
    border-color: white !important;
} */
