.loader{
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circular-progress-div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16vw;
  }
  
  .circular-progress-main-page-div {
    height: 370px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .containerHeight .circular-progress-div{
    height: 375px !important;
  }
  
  .no-data-main-page-svg-div {
    height: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .no-data-svg-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16vw;
  }
  
  .circular-progress-div-small {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }