.EmailBox .MuiDialog-paper.MuiDialog-paperScrollPaper{
    height:621px !important;
    width:720px !important;
    border-radius: 20px !important;
}

.EmailBox .webkit-scrollbar {
  height: 98%;
}

.EmailBox .MuiDialog-paperWidthSm {
    max-width: 720px;
    max-height: 621px;
}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: #EFFDF7 !important;
}

/* .Selectbox {
    width:400px !important;
} */

/* .Selectbox .MuiSelect-selectMenu {
    width: 400px !important;
} */

.Selectbox .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #21DA8C;
  border-width: 2px;
}

.MuiOutlinedInput-root {
    border-radius: 5px !important;
}

/* .dialogtitlefeedback{
  height:64px !important;
} */

.dialogfeedback .MuiDialog-paper.MuiDialog-paperScrollPaper {
  border-radius: 24px;
}

.EmailIcons {
    display: inline-block;
    vertical-align: middle;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }

  .buttonCust {
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: 0;
  }
  
  /* .container {
    border:1px solid rgba(0, 0, 0, 0.38);
    width:320px;
    height: 400px;
    margin-left: 10px;
    border-radius: 10px;
    padding: 5px;
    overflow: scroll;
    align-items: center;
    display: flex;
 
  } */

  /* .container { */
    
    /* border: 1px solid rgba(0, 0, 0, 0.38);
    width: 320px;
    height: 400px;
    margin-left: 10px;
    border-radius: 10px; */
    /* overflow: hidden; */
    /* overflow-y: auto; */
    /* position: relative; */
    /* display: flex;
  flex-direction: column; */
  /* } */
  
  /* .container-content {
    padding: 5px;
    overflow: auto;
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  } */

  .select-template-grid{
    border: 1px solid rgba(0, 0, 0, 0.38);
    width: 320px;
    height: 400px;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }

  .cusButton{
    margin: 0 !important;
    padding: 0 !important;
  }

  .cusAutoComp{
    margin: 0 !important;
    padding: 0 !important;
  }

  .cusAutoComp .MuiFormControl-marginDense {
      margin: 0 !important;
  }

  .cusAutoComp .MuiOutlinedInput-notchedOutline{
    height: 32px !important;
  }
  
  .select-container-grid {
    flex: 1;
    overflow: auto;
  }
  /* .container:hover .copy-mail-button,
  .container:hover .specific-class .copy-mail-button {
    display: 'none';
    background-color: #F55843;
  } */

  .copy-mail-button {
    /* display: flex;
    padding: 8px 10px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 0px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 8px;
    width: 124px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid rgba(55, 234, 158, 0.5);
    border-radius: 6px;
    position: absolute;
    top: 139px;
    right: 34px
  }

  .regenerate-mail-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    width: 180px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid rgba(55, 234, 158, 0.5);
    border-radius: 8px;
  }

  .bottomicons {
    position: absolute;
    bottom: 18.5%;
    left: 52%;
    width: 305px;
  }

  .thumbsupicon{
    background-color:rgba(55, 234, 158, 0.08) !important;
    width: 28px !important;
    border-radius: 8px !important;
  }

  .thumbsupicon:hover {
    background-color: rgba(55, 234, 158, 0.08) !important;
    width: 28px !important;
    border-radius: 8px !important;
}

  .thumbsdownicon{
    background-color:rgb(252,238,236) !important;
    width: 28px !important;
    border-radius: 8px !important;
  }

  .slidingmailicon {
    left: 10px;
    visibility: hidden;
    transition: transform 5s ease-in-out 1s step(5px);
  }

  .contactname:hover .slidingmailicon {
    left: 0px;
    visibility: visible;
    transform: translate(-10px, 0px);

  }


  .parent {
    position: relative;
  }
  
  .child {
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;
  }
  
  .parent:hover .child {
    transform: translateX(-30%);
    opacity: 1;
  }




  .thumbsdownicon:hover{
    background-color: rgb(252,238,236) !important;
    width: 28px !important;
    border-radius: 8px !important;
  }



.custDiaCont .MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
    padding-top: 0;
    padding-bottom: 0;
}

.custDiaCont .MuiDialogContent-root:first-child {
  padding-bottom: 0;
}

.custDiaCont .MuiDialogContent-root {
  padding: 0px 24px 0px 24px;
}

.custdiagactions .MuiDialogActions-root {
  padding: 0;
  height: 40px;
}

.custdiagactions .MuiButtonBase-root-MuiButton-root:hover {
  background-color: rgba(55, 234, 158, 0.08);
}


  .thumb-up-icon{
    position: absolute;
    right: 5.5%;
    bottom: 125px;
    color: #21DA8C;
    display: flex;
  }

  .thumb-down-icon{
    position:absolute;
    right: 10.5%;
    bottom: 120px;
    color: #F55843;
    display: flex;
  }

  .thumb-down-span{
    display: flex;
flex-direction: row;
align-items: center;
padding: 6px;

position: absolute;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;

/* error/lightBg */

background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #DD5B49;
border-radius: 8px;
  }

  .text {
    position: absolute;
    top: 0;
    right: 0;
  }

.buttonCust .Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
}

/* .cusButton .css-1rwt2y5-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
} */

/* .cusButton .Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
} */


 

  .feedback-text-area {
    border:1px solid rgba(0, 0, 0, 0.38);
    border-radius: 5px;
    padding:10px;
    align-items:center;
    display: flex;
    resize: none;
    width: 410px;
    height: 170px;
    font-size: 16px;
    font-family: "Inter";
    line-height: 19px;
    letter-spacing: 0.15px;
    &:focus {
      outline: none;
      border-color: #21DA8C;
      /* box-shadow: 0 0 5px green; */
    };


  }

  .feedback-text-area::placeholder{
    
width: 408px;
height: 19px;

font-family: "Inter";
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;

display: flex;
align-items: center;
letter-spacing: 0.15px;

color: #CACACA;

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
  }

  .last-copy-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    
    width: 148px;
    height: 40px;
    
    background: #21DA8C;    
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
}

.MuiInputBase-input {
  height: 1.5rem;
}