.logout-dialog * {
  box-sizing: border-box;
}

.logout-dialog .MuiPaper-root {
  width: 628px;
  max-width: none !important;
  margin: 0;
  border: 1px solid #DFE2DE;
  border-radius: 8px !important;
}

.logout-dialog .MuiDialogContent-root {
  height: 160px;
  padding: 0 !important;
  display: grid;
  grid-template-rows: 72px auto 1fr;
  /* gap: 24px; */
}

.logout-dialog .header-content {
  height: 72px;
  display: flex;
  padding-left: 24px;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  color: #3D3D3B;
}

.logout-dialog hr {
  /* margin: 0 -24px; */
  height: 1px;
  background: #F5F5F5;
  border: none;
}

.logout-dialog .description-content {
  display: inline;
  margin: auto;
  padding-left: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: #444444;
}

.logout-dialog .log-out-now {
  height: 35px;
  padding: 10px 16px;
  border-radius: 12px;
  border: 1px solid #DFE2DE;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  cursor: pointer;
}

.logout-dialog .continue-session {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  color: white;
  background-color: #016B40;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  cursor: pointer;
}

.logout-dialog .MuiDialogActions-root {
  height: 64px;
  padding: 0 !important;
  padding-right: 16px !important;
  gap: 16px;
}